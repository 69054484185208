/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, withPrefix } from "gatsby";

import { Provider } from "react-redux";
import { I18nContextProvider } from "../i18n/index";
import { IntlProvider } from "react-intl";

import configureStore from "../redux/index.js";

import MailchimpSubscribe from "../components/AC-Advertising/MailchimpSubscribe";
import MenuTopBar from "../header/MenuTopBar";
import Compare from "../header/Compare";

import Common from "../shared/components/Common";
import Footer from "../components/AC-Footer/Footer";

import "../assets/css/googleFonts.css";
import "../assets/css/menu.css";
import "../assets/css/minified.css";
import "../assets/css/avetti.css";
import "../assets/css/mailchimp.css";

import "../assets/css/sl.css";
import "../assets/css/b2b2c.css";
import "../assets/css/fontello.css";
import "../assets/jss/demo-ar.scss";
import "../assets/css/purgeignore.css";

import LocationBar from "../components/AC-Location/LocationBar";
import PromotionComponent from "../components/AC-Advertising/PromotionComponent";
import { Helmet } from "react-helmet";

export const store = configureStore();

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      allAvettiMenu {
        nodes {
          childs {
            cid
            name
            URL
            description
          }
        }
      }
      allAvettiCategory(filter: { name: { in: "Sellers" } }) {
        nodes {
          name

          itemsFirstPage {
            id
            title
            code
            desc
            properties {
              lng
              lat
              Brand
            }
          }
        }
      }
    }
  `);

  return (
    <I18nContextProvider>
      <IntlProvider locale={"en"}>
        <Provider store={store}>
          <Common />
          <div>
            <MenuTopBar
              data={data.allAvettiMenu.nodes[0].childs}
              // brands={data.allAvettiCategory.nodes[0].itemsFirstPage}
            />
            {/* <LocationBar /> */}
            <PromotionComponent />
            <Compare />
          </div>

          {children}
          <MailchimpSubscribe />
          <Footer />
        </Provider>
      </IntlProvider>
    </I18nContextProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
