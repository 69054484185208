import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import classes from "./Styles/MailchimpSubscribe.module.css";
import addToMailchimp from "gatsby-plugin-mailchimp";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { Link } from "gatsby";

const MailchimpSubscribe = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedGDPR, setCheckedGDPR] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = e => {
    console.log(e.target.name);
    if (e.target.name === "name") setName(e.target.value);
    if (e.target.name === "email") setEmail(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();

    console.log("submit", email);

    if (checked) {
      setCheckedGDPR(true)
      addToMailchimp(email, { FNAME: name, "gdpr[51303]": "Y" })
        .then(({ msg, result }) => {
          console.log("msg", `${result}: ${msg}`);

          if (result !== "success") {
            throw msg;
          }
          if (result === "success") {
            setSucceeded(true);
          }
          // alert(msg);
        })
        .catch(err => {
          console.log("err", err);
          alert(err);
        });
    } else {
      // alert("not checked");
      setCheckedGDPR(false)
    }
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.iconWrapper}>
          <MailOutlineIcon className={classes.icon} />
        </div>

        <div className={classes.headingWrapper}>
          <span className={classes.heading}>Subscribe</span>
          <span className={classes.subHeading}>
            Subscribe to our newsletter for access to promotions and product
            updates!
          </span>
        </div>

        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.formInput}>
            <input
              type="email"
              onChange={handleChange}
              placeholder="Email"
              name="email"
              className={classes.input}
            />
            <input
              type="name"
              onChange={handleChange}
              placeholder="First Name"
              name="name"
              className={classes.input}
            />
          </div>

          <div id="mergeRow-gdpr" className={classes.gdpr}>
            <fieldset
              class="mc_fieldset gdprRequired mc-field-group"
              name="interestgroup_field"
            >
              <label class="checkbox subfield" for="gdpr_51303">
                <input
                  type="checkbox"
                  id="gdpr_51303"
                  name="gdpr[51303]"
                  value="Y"
                  className={classes.gdprInput}
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <p className={classes.gdprText}>
                  I agree to receiving newsletters from Barrie Farmers Market.
                  You can unsubscribe at any time. See our{" "}
                  <Link to="/privacy-policy">Privacy Policy </Link>
                  for more details.
                </p>
                <span className={checkedGDPR ? classes.gdprRequiredHidden : classes.gdprRequired}>
                  Please select checkbox to subscribe
                </span>
              </label>
            </fieldset>
          </div>

          <div>
            <input
              className={!succeeded ? classes.submitBtn : classes.notSubscribed}
              type="submit"
              value="SUBSCRIBE"
            />
            <div className={succeeded ? classes.subscribed : classes.notSubscribed}>
                Almost finished... We need to confirm your email address. To complete the subscription process, please click the link in the email we just sent you.
            </div>
          </div>
        </form>
        {/* <div className={classes.gdprLegal}>
          <p>
            You can unsubscribe at any time by clicking the link in the footer
            of our emails. For information about our privacy practices, please
            read our <a href="">privacy policy</a>.
       
            We use Mailchimp as our marketing platform. By clicking subscribe, you acknowledge that your information will be transferred
            to Mailchimp for processing.{" "}
            <a href="https://mailchimp.com/legal/" target="_blank">
              Learn more about Mailchimp's privacy practices here.
            </a>
          </p>
        </div> */}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div onClick={handleClose} className={classes.modalWrapper}>
          <div
            className={classes.modal}
            style={{
              display: !succeeded ? "flex" : "none"
            }}
            onClick={e => e.stopPropagation()}
          >
            <span
              aria-hidden="true"
              className={classes.closeBtn}
              onClick={handleClose}
            >
              ×
            </span>
            <span className={classes.heading}>test</span>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MailchimpSubscribe;
